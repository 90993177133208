.not-logged-in.page-browse-films h1 {
	margin-bottom: 1.5rem;
}

.page-browse-films .feedback--error {
  max-width: 100%;
}

/* FILTER BAR */

.browse-films__filter {
  background-color: scale-color($light-pastel, $lightness: 94%);
  margin: 0 0 2em 0;
  @media only all and (max-width: 63.7499em) {
    .browse-films__filter__layout-container {
      padding: 0 1rem;
    }
  }
  @media only all and (min-width: 41.25em) and (max-width: 63.7499em) {
    .browse-films__filter__layout-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > * {
        flex: 0 0 48%;
      }
    }
  } 
  @media only all and (min-width: 63.75em) {
    .browse-films__filter__layout-container { 
      display: flex;
      justify-content: space-between;
    }
  }
}

// Overall filter bar breakpoint for showing / hiding the filters

.browse-films__filter__summary {
  display: list-item;
  padding: 1em;
  width: 100%;
  background-color: scale-color($light-pastel, $lightness: 79%);
  font-weight: 600; 
  font-size: 1.1rem;
  @media only all and (min-width: 63.75em) {
    display: none;
  }
}

.browse-films__filter__heading {
  display: none;
  @media only all and (min-width: 63.75em) {
    display: inline-flex;
    flex: 0 0 2.2rem;
    position: relative;
    min-height: 6.875rem;
    padding: 0 0 0 0.15rem;
    text-align: center;
    background-color: scale-color($light-pastel, $lightness: 79%);
    .browse-films__filter__heading__text {
      position: absolute;
      top: calc(50% - 2.3rem);
      transform: rotate(-90deg);
      color: #8A2C1E;
      transform-origin: initial;
      text-transform: uppercase;
      font-weight: 200;
      font-size: 1.1rem;
      letter-spacing: 0.2rem;
      display: inline-block;
      margin: 3rem 0 3rem 0;
      max-width: 2rem;
    }
  }
}

.browse-films__filter__reset {
  @media only all and (max-width: 63.7499em) {
    a {
      display: inline-block;
      margin: 2rem 0;
    }
  }
  @media only all and (min-width: 63.75em) {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    a {
      margin-right: 1rem;
    }
  }
  @media only all and (min-width: 63.75em) and (max-width: 74em) {
    .browse-films__filter__reset__extra {
      display: none;
    }
  }
  @media only all and (min-width: 74em) {
    a {
      margin-right: 2.8rem;
    }
  }
    
}

// Filters not having disclosure arrows
.browse-films__filter__chooser-container {
  details > summary {
    list-style: none;
  }
  details > summary::-ms-expand,
  details > summary::marker, /* Latest Chrome, Edge, Firefox */
  details > summary::-webkit-details-marker /* Safari */ {
    display: none;
  }
}

// Item Lists

.browse-films__filter__chooser__label {
  display: inline-block;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 1.2rem 0 0.2rem 0;
}

.browse-films__filter__chooser-container {
  position: relative;
  min-width: 7.8rem;
  @media only all and (min-width: 63.75rem) {
    margin-bottom: 1.5rem;
  }
}

.browse-films__filter__chooser[open] .browse-films__filter__chooser__list {
  position: absolute;
  z-index: 500;
  top: 5.2rem;
  left: 0.25rem;
  li {
    list-style-type: none;
    &::before {
      content: "";
    }
  }
  li:not(.browse-films__filter__chooser__list__cta) {
    margin: 0;
    padding: 0.4rem 0.8rem;
    @media only all and (min-width: 63.75rem) {
      padding: 0.4rem 0.8rem;
    }  
    font-size: 1rem;
    background-image: linear-gradient(to bottom, #e5e5e5 0%, #e0e0e0 100%);
    border-bottom: 0.1rem solid #ccc;
    border-left: 0.1rem solid #ccc;
    border-right: 0.1rem solid #ccc;
  }
  label {
    color: #222;
    margin: 0 0 0 0;
  }
  li.browse-films__filter__chooser__list__cta {
    button {
      width: 100%;
      color: $white;
      background-color: #222;
      padding: 0.6rem 0.8rem;
      margin: 0;
      font-weight: 600;
      font-size: 1rem;
      height: auto;
      border-bottom-right-radius: 0.35rem;
      border-bottom-left-radius: 0.35rem;
    }
  }
}

.browse-films__filter__chooser[open] .browse-films__filter__chooser__list.browse-films__filter__chooser__list {
  min-width: calc(100% - 0.5rem);
  @media only all and (min-width: 63.75rem) {
    min-width: 24rem;
  }
  max-width: 90vw;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 1 0 9rem;
  }
  li.browse-films__filter__chooser__list__cta {
    flex: 1 0 100%;
  }
}

.browse-films__filter__chooser[open] .browse-films__filter__chooser__list.browse-films__filter__chooser__list--narrow {
  display: unset;
  min-width: calc(100% - 0.5rem);
}

.browse-films__filter__chooser[open] .browse-films__filter__chooser__list.browse-films__filter__chooser__list--wider {
  display: unset;
  min-width: calc(100% - 0.5rem);
  @media only all and (min-width: 63.75rem) {
    min-width: 15rem;
  }
}

// Display of current filters as clickable tags 

.browse-films__filter-tags {
  margin: 0 0 1.5rem 0;
  .browse-films__filter-tags__heading {
    color: #6F271C;
    font-size: 1.4rem;
  }
  a:last-child {
    border-bottom: 0;
    margin-bottom: 1.5rem;
    padding-top: 0.4rem;
    text-decoration: underline;
  }
  @media only all and (min-width: 63.75em) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .browse-films__filter-tags__heading {
      margin: 0 1.5rem 0 0;
    }
  }
}

.browse-films__filter-tags__tag {
  display: inline-flex;
  background-color: #FFCDB3;
  border: 0.2rem solid #FFCDB3;
  border-radius: 0.375rem;  
  font-weight: 600;
  color: #6F271C;
  cursor: pointer;
  margin: 0 1.5rem 1.5rem 0;
  align-items: stretch;
  
  .browse-films__filter-tags__tag__type {
    background-color: #FFFFFF;  
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
  }

  .browse-films__filter-tags__tag__value {
    padding: 0.25rem 0.5rem;
  }

  .browse-films__filter-tags__tag__close {
    background-color: #d49b86;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 0.25rem 0.35rem;
    display: inline-flex;
    align-items: center;
  }

}


/* Filament Group Select CSS */
.browse-films__filter__chooser__summary {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 400;
  color: #444;
  line-height: 1.1;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
  border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}

.browse-films__filter__chooser__summary:hover {
  border-color: #888;
}

.browse-films__filter__chooser__summary:focus {
  border-color: #aaa;
  color: #222;
  outline: none;
  text-decoration: underline;
}

.browse-films__filter__chooser__summary option {
  font-weight: normal;
}

// HIDE FILTER BAR in old MS browsers that canna deal

.ms-browser {
  .browse-films__filter {
    display: none;
  }
  .short-film-library {
    .list__content__container .heading .span,
    .list__content__back-to-top {
      display: none !important;
    }
  }
} 