// StyleGuide Imports (dependencies for this pattern): 
@import '../_2-mixins.scss'; @import '../_4-colours.scss';

.items {
  margin: 0 0 3em 0;
}

.items--block {
  padding: 1.5em 1.5em 1em 1.5em;
  background-color: scale-color($contrast-alt, $lightness: 85%);
}

.items .heading__smallest {
  margin: 0 0 0.6em 0;
}

.items ul {
  list-style-type: none;
}

.items .items__item {
  &:before {
    content: "— ";
  }
  & a:hover {
    color: $dark-text;
    border-color: $dark-text;
    border-bottom: 0.1em solid;
  }
  &:after {
    content: " »";
  }
}

.items--block .items__item {
  &:before {
    content: none;
  }
  & a {
    color: $dark-text;
    border-color: $dark-text;
  }
  & a:hover {
    color: scale-color($dark-text, $lightness: -30%);
    border-bottom: 0.1em solid;
  }
}