body.privacy-consent-active {
	overflow: hidden;
	position: fixed;
	height: 100vh; 
}

.privacy-settings__button {
	color: $white;
	background-color: scale-color($dark, $lightness: -50%);
	border: none;
	display: block;
	font-size: 1.2em;
	width: 100%;
	text-decoration: underline;
	padding: 1em 0 5.3em 0;
	@media only all and (min-width: 40em) { 
		padding: 1em 0 2.3em 0;
	} // end min 40em
}

.privacy-consent-container {

	height: 100vh;
	width: 100vw;
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	
	.heading {
		color: $white;
	}
	
	.heading--large {
		margin-bottom: 0.6em;
		font-size: 1.4em;
		@media only all and (min-height: 29em) { font-size: 1.8em } // end min 29em
	}
	
	.heading--uppercase {
		text-transform: uppercase;
	}
	
	.heading--smallest {
		border-bottom: 0.1em solid $white;
		padding-bottom: 0.3em;
		font-weight: normal;
		font-size: 1.1em;
	}

	.privacy-settings__close {
		display: none;
		// this is undone by JS when the user has set prefs, so they can close panel
	}
	
	.privacy-consent__cover {
		@media only all and (min-width: 22em) { 
			width: calc(100vw - 22em);
			height: 100vh;
			float: left;
			background-color: scale-color($dark, $lightness: -80%);
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)"; /* IE 8 */
			filter: alpha(opacity=90);  /* IE 5-7 */
			-moz-opacity: 0.9;          /* Netscape */
			-khtml-opacity: 0.9;        /* Safari 1.x */
			opacity: 0.9;               /* Good browsers */
		} // end min 22em
	}
	
	.privacy-consent__form {
		margin: 0;
		padding: 0;
		height: 100vh;
		width: 100vw;
		max-width: 22em;
		overflow: hidden;
		position: static;
		background-color: $mid-text;
		@media only all and (min-width: 22em) { 
			width: 22em;
			float: right;
		} // end min 22em
	}
	
	.privacy-consent__form__front {
		height: 100vh;
		padding: 5vh 2.4em 5vh 2.4em;
		@media only all and (min-height: 35em) and (min-width: 41em) { 
			padding: 10vh 2.4em 10vh 2.4em;
		} // end min 35em high 41em wide
		position: static;
		overflow-y: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}
	
	.privacy-consent__form__front__message {
		font-size: 1em;
		@media only all and (min-height: 33em) { font-size: 1.1em } // end min 33em
	}
	
	button.btn,
	input.btn {
		height: 2.8em;
		margin-bottom: 0.5em;
	}
	
	.btn {
		border-color: scale-color($mid-text, $lightness: -40%);
		width: 12em;
		text-align: center;
		font-size: 1em;
		@media only all and (min-height: 31em) { font-size: 1.1em } // end min 31em
	}
	
	.privacy-consent__form__front__or {
		display: block;
		color: $white;
		text-align: center;
		max-width: 12em;
		margin-bottom: 0.75em;
	}
	
	.privacy-consent__form__customise {
		height: 100vh;
		padding: 5vh 2.4em 5vh 2.4em;
		@media only all and (min-height: 35em) and (min-width: 41em) { 
			padding: 10vh 2.4em 10vh 2.4em;
		} // end min 35em high 41em wide
		position: static;
		overflow-y: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}
	
	.privacy__settings__form__customise__block {
		margin-bottom: 2em;
	}
	
	.privacy-consent__form__customise label,
	.privacy-consent__form__customise .required-label {
		font-size: 1.1em;
		font-weight: bold;
		color: $dark-text;
		font-family: courier new, courier, monospace;
		margin: 0 0 0.2em 0;
		display: block;
	}
	
	.privacy-consent__form__customise p {
		font-size: 0.875em;
	}
	
	.privacy-consent__form__customise input[type=submit] {
		margin-bottom: 2em;
	}
		
} // end .privacy-consent-container

//// IE Fixes

.lt-ie9 { // target versions of IE lower than 9 who don't have calc() in CSS
	
	.privacy-consent__form {
		width: 35%;
	}
	
	.privacy-consent__cover {
		width: 65%;
	}
}