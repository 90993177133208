.single-film__video {
  max-width: 40em;
  margin: 0 0 3em 0;
}

.single-film__video {
  .restricted {
    margin-bottom: 1.5rem;
  }
  .home__login-warning {
    display: inline-flex;
    height: auto;
    @media only all and (min-width: 28.75em) {
      height: 45vw;
    }
    max-height: 25rem;
    .home__login-warning__icon,
    .home__login-warning__message {
      height: auto;
    }
    .home__login-warning__message {
      width: 87%;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      background-color: transparent;
      span {
        text-decoration: underline;
      }
      &::before {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: url('../../assets-svg/circle-arrow.svg');
          opacity: .15;
          content: "";
          z-index: -1;
          background-repeat: no-repeat;
          background-position: 50% 48%;
          background-size: 50% 50%;
          @media only all and (min-width: 50em) { 
            background-size: 10rem 10rem;
          }
        }
      

      h2 {
        max-width: 100%;
      }
    }
    .home__login-warning__icon {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      i {
        margin-top: -2rem;
      }
    }

  }
}

.single-film__intro {
  margin-top: 2em;
}

.node-type-film-clip .js-tabs .tab-questions-panel,
.node-type-film-clip .js-tabs .tab-watching-panel,
.node-type-film-clip .js-tabs .tab-analysing-panel,
.node-type-film-clip .js-tabs .tab-creative-panel {
  margin: 2.5em 0 0 0;
}

// Logged in?

.node-type-film-clip.logged-in .video-container {
  margin: 0 0 1.5em 0;
} 

.node-type-film-clip.logged-in .single-film__downloads .btn {
  font-size: 0.9em;
  font-weight: 300;
  text-align: center;
  margin-bottom: 0.5em;
}

.node-type-film-clip.logged-in .single-film__downloads__help {
	display: block;
	border: none;
	text-decoration: underline;
	margin: 1em 0 0 0;
	max-width: 80%;
}

// Hide related clips sidebar (higher in source order than tabs) until wide enough viewport

.node-type-film-clip .related-clips--sidebar {
  display: none;
}

.node-type-film-clip .feedback--error {
  max-width: 100%;
}

@media only all and (min-width: 37em) {
	
	.node-type-film-clip.logged-in .single-film__downloads__help {
		display: inline-block;
		max-width: auto;
		width: 45%;
		margin: 0 0 0 0.5em;
	}
	
} // min 37em

@media only all and (min-width: 32.5em) {
  
  .single-film__activities-heading {
    .heading {
      float: left;
    }
    
    .single-film__printall {
      float: right;
      margin-right: 0;
    }
  }
  
} //min 32.5em

@media only all and (min-width: 63.9375em) {
  
  .node-type-film-clip h2 {
    max-width: 70%;
  }
  
} //min 63.9375em

@media only all and (min-width: 79em) {
  .single-film__video {
    float: left;
    min-width: 40em;  
  }
  
  .node-type-film-clip .js-tabs {
    margin-bottom: 2em;
    display: inline-block;
    width: 100%;
  }
  
  // Show related clips sidebar;
  
  .node-type-film-clip .related-clips--sidebar {
    display: inline-block;
    float: right;
    max-width: 30em;
  }
  
  // Hide related clips under tabs
  
  .node-type-film-clip .related-clips--bottom {
    display: none;
  }
  
  
} // min 79em