// StyleGuide Imports (dependencies for this pattern): 
@import '../_2-mixins.scss'; @import '../_4-colours.scss';

/* Forms */

form {
  display: inline-block;
  width: 100%;
  max-width: 36em;
  background-color: $dark;
  padding: 1em 1em 0em 1em;
  margin: 1.5em 0 1em 0;
}

form p {
  color: $white;
}

form a {
  color: $white;
  border-color: $white;
  &:hover {
	  color: $light-pastel;
    border-color: $light-pastel;
  }
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[list],
input[type="file"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="range"],
input[type="search"],
textarea,
select {
  font-size: 1.1em;
  display: inline-block;
  height: 2.375em;
  width: 100%;
  padding: 0.375em 0.625em; 
  margin-bottom: 1.5em;
  /* ^ The 0.375em vertically centers text on FF, 
  ignored by Webkit */
  background-color: #fff;
  border: 0.2em solid $dark;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[list],
input[type="file"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="range"],
input[type="search"] {
  max-width: 20em;
}

.form-item {
  margin: 0 0 1.5em 0;
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="text"],
  input[type="tel"],
  input[type="url"],
  input[type="password"],
  input[list],
  input[type="file"],
  input[type="date"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  input[type="range"],
  input[type="search"],
  textarea,
  select {
    margin-bottom: 0.5em;
  }
  
  .description {
    color: $light-pastel;
    font-size: 1em;
  }
  
}

.checkbox {
  display: inline-block;
  margin: 1em 1.5em 2em 0 ;
}

.checkbox input {
  margin-right: 0.5em;
}

/* Removes awkward default styles on some inputs for mobile */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[list],
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; 
}

textarea {
  min-height: 4.0625em;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  margin-bottom: 1em; 
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[list]:focus,
textarea:focus,
select:focus {
  border: 0.2em solid $light-pastel;
  outline: 0; 
}

label,
legend {
  display: block;
  color: $white;
  font-size: 1.1em;
  margin-bottom: 0.4em;
  font-weight: 300; 
}

fieldset {
  padding: 0;
  margin-bottom: 1.5em;
  border-width: 0;
  border: 0;
}

fieldset ul {
  list-style-type: none;
}

input[type="checkbox"] {
  display: inline; 
}

input[type="radio"] {
  display: inline-block;
  margin: 0 0.5em 0.5em 0;
}

input[type="file"] {
  background-color: scale-color($dark, $lightness: -50%);
  color: $white;
}

.form-required {
  font-weight: 300;
  color: $error;
}

.format {
  font-weight: 300;
  font-style: italic;
  color: $light-pastel;
  margin-left: 0.75em;
}

input[type="search"] {
  box-sizing: border-box;
}

select[multiple] {
  height: auto;
}

textarea[disabled], input[disabled], select[disabled] {
  background-color: #ccc;
  color: #666;
}

textarea[disabled]:hover, input[disabled]:hover, select[disabled]:hover {
  cursor: not-allowed;
}

select:-internal-list-box option:checked {
  background-color: $light;
}

// Say goodbye to Drupal's bitmap form message iconography:

.messages {
	background-image: none !important;
	padding: 1.2em 1em !important;
	margin: 1em 0 2em 0 !important;
	ul li {font-weight: 600;}
	p {margin: 0;}
}

/* Form buttons are covered in the 'Buttons
  and calls to action' section above */

@media only all and (min-width: 30em) {

    form {
      padding: 2em 2em 0.5em 2em;
    }

} // min 30em;

.captcha .fieldset-description {
  color: $light-pastel;
  margin-bottom: 2em;
}

@media screen and (max-width: 30em){
  #rc-imageselect, .g-recaptcha {
    transform:scale(0.77);
    -webkit-transform:scale(0.77);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
  }
  .captcha .fieldset-description {
    max-width: 15em;
  }  
}