// Hide our home login trigger until we're at a large enough viewport that we're hiding the original trigger

.front.logged-in .home__login__trigger {
  display: none;
}

////// Heading

.home__heading {
	margin: 0.7em 0 1.5em 0;
	max-width: 28.3em;
	font-size: 1.4em;
}

////// Login Call to Action

.home__login-warning {
	border: 0.2em solid $mid;
	display: inline-block;
	margin: 0 0 2.5em 0;
	//max-width: 62.25em;
	&:hover {
		border: 0.2em solid $light;
		@include transition(border-color,0.05s);
	}
	&:hover .home__login-warning__message h2 {
		color: $light;
		@include transition(color,0.05s);
	}
	&:hover .home__login-warning__cta {
		background-color: $light;
		@include transition(background-color,0.05s);
	}
	&:hover .home__login-warning__cta span {
		border-bottom: 0.1em dotted $white;
	}
}

.restricted .home__login-warning {
	margin: 0 0 0 0;
}

.restricted .home__login-warning:hover {
	border-bottom: 0.2em solid $light;
}

.home__login-warning__icon {
	text-align: center;
	padding: 1em;
	.icon-padlock-cta {
		width: 2.375em;
		height: 3.4375em;
	}
}

.home__login-warning__message {
	padding: 1em 1em 0 1em;
	h2 {
		color: $mid;
		margin: 0 0 0.5em 0;
	}
}

.home__login-warning__cta {
	color: $white;
	background-color: $mid;
	text-align: center;
	padding: 1em;
	div {
		display: inline-block;
		margin: 0 auto;
		width: auto;
	}
	span {
		display: block;
		font-size: 1.2em;
		float: left;
		margin-top: 0.25em;
	}
	.icon-circle-arrow-white {
		display: block;
		width: 2.125em;
		height: 2.125em;
		margin: 0 0 0 0.6em;
		float: left;
	}
}

////// Browse Films Block

.home__browse-films {
	display: inline-block;
	margin: 0 0 2em 0;
}

.home__browse-films__messaging {
	background-color: $contrast-alt-light;
	padding: 1em;
	.heading {
		margin: 0 0 0.5em 0;
	}
}

.home__browse-films__image {
	img {
		max-width: 100%;
		width: 100%;
	}
}

////// Featured items

.home__featured-items {
  margin-bottom: 1.5em;
}

.home__featured-items__item {
	display: inline-block;
	width: 100%;
	margin: 0 0 1.5em 0;
	.featured-items__item__content {
		background-repeat: repeat;
		color: $white;
		padding: 1em 1em 1.5em 1em;
		.featured-items__item__content__heading {
			margin: 0 0 0.8em 0;
		}
		p {
			color: $white;
			margin: 0;
		}
		a {
			color: $white;
			font-weight: bold;
			border-color: $white;
		}
	}
	.featured-items__item__image {
		img {
			max-width: 100%;
		}
	}
}

//// Teaching Resources

.home__teaching-resources {
	margin: 0 0 2em 0;
	display: inline-block;
}

.home__teaching-resources__item {
	background-color: $contrast-alt;
	margin: 0 0 1.5em 0;
	.teaching-resources__item__heading {
		color: $white;
		border-bottom: 0.3em solid $contrast-alt-light;
		padding: 0.5em 0.5em 0.7em 0.5em;
		margin: 0;
		span {
			float: left;
			padding-top: 0.15em;
		}
		i {
			float: right;
		}
	}
	&:nth-child(2),
	&:nth-child(4) {
		background-color: $mid;
		.teaching-resources__item__heading {
			border-color: $light;
		}
		.teaching-resources__item__heading span {width: 85%;}
	}
	&:nth-child(3) {
		background-color: $darkest;
		.teaching-resources__item__heading {border-color: scale-color($darkest, $lightness: 25%);}
	}
	&:nth-child(5) {
		background-color: $contrast;
		.teaching-resources__item__heading {border-color: $contrast-light;}
	}
	p {
		color: $white;
		padding: 1em;
		margin: 0;
	}
	a {
		font-size: 1.1em;
		color: $white;
		border-color: $white;
		margin: 0 1em 1.5em 1em;
		display: inline-block;
	}
}


////// MEDIA QUERIES

@media only all and (min-width: 20em) {
  
  .home__heading {
		margin: 0em 0 1em 0;
		font-size: 1.6em;
	}
	
	.home__teaching-resources__item {
		.teaching-resources__item__heading {
			padding: 0.5em 1em 0.6em 1em;
		}
	}
  
} // min 20em

@media only all and (min-width: 25.265em) {
   /*
  .calls-to-action__item--browse .cta span {
    padding-top: 0.5em;
  }*/

} // min 25.265em


@media only all and (min-width: 32em) {
  
  .home__browse-films__messaging {
		padding: 2em 2em 1em 2em;
	}
  
	.home__featured-items__item {
		&:nth-child(1){background-color: $dark;}
		&:nth-child(2){background-color: scale-color($contrast, $lightness: -30%);}
		.featured-items__item__content {
			width: 60%;
			float: left;
			padding-bottom: 1em;
		}
		.featured-items__item__image {
			width: 40%;
			float: left;
			img {
				max-height: 8.875em;
			}
		}
	}

  .home__heading {
		margin: 0.4em 0 1.5em 0;
		font-size: 2em;
	}


} // min 32em

@media only all and (min-width: 40em) {
	.home__login-warning__icon {
		width: 25%;
		float: left;
		height: 8.25em;
		.icon-padlock-cta {
			margin-top: 1.5em;
			display: inline-block;
		}
	}

	.home__login-warning__message {
		width: 75%;
		float: left;
		background-color: $white;
		height: 8.25em;
	}
	
	.home__login-warning__cta {
		width: 100%;
		display: inline-block;
		padding: 0.75em;
	}

} //min 40em

@media only all and (min-width: 41em) {
	
	.home__featured-items__item {
		&:nth-child(1){background-color: $dark;}
		&:nth-child(2){background-color: scale-color($contrast, $lightness: -30%);}
		max-width: 39em;
		.featured-items__item__content {
			padding: 1.8em;
			width: 65%;
		}
		.featured-items__item__image {
			width: 35%;
			float: left;
			max-width: 16em;
			img {
				max-height: 9.5em;
			}
		}
	}
	
	.home__teaching-resources__item {
		width: 47.5%;
		float: left;
		min-height: 21.875em;
		&:nth-child(2n-1) {
			margin-right: 5%;
		}
		&:nth-child(4) {
			background-color: $contrast;
			.teaching-resources__item__heading {border-color: $contrast-light;}
			.teaching-resources__item__heading span {width: 85%;}
		}
	}

} //min 41em

@media only all and (min-width: 45em) {
	
	.home__teaching-resources__item {
		.teaching-resources__item__heading {
			font-size: 1.3em;	
		}
	}

	
} //min 45em


@media only all and (min-width: 50em) {
  
  .home__heading {
		margin: 0.4em 0 1.4em 0;
		font-size: 2.2em;
	}
  
  .home__login-warning__icon {
		width: 20%;
	}

	.home__login-warning__message {
		width: 80%;
	}
	
	.home__teaching-resources__item {
		height: 18em;
	}

} // min 50em

@media only all and (min-width: 56em) {
	
	.home__login-warning__icon {
		width: 15%;
		height: 6.8em;
		.icon-padlock-cta {
			margin-top: 0.7em;
		}
	}

	.home__login-warning__message {
		width: 85%;
		height: 6.8em;
	}
	
	.home__login-warning__cta {
		width: 100%;
		display: inline-block;
		padding: 0.75em;
	}
	
	.home__browse-films__messaging {
		width: 60%;
		float: left;
		height: 27.25em;
	}
	
	.home__browse-films__image {
		width: 40%;
		float: left;
		background-color: $darkest;
		height: 27.25em;
		border-left: 0.2em solid $white;
		img {
			margin-top: 7.5em;
		}
	}
	
} //min 56em

@media only all and (min-width: 64em) {
	
	.home__browse-films__messaging {
		width: 55%;
		height: 25em;
	}
	
	.home__browse-films__image {
		width: 45%;
		height: 25em;
		img {
			margin-top: 5em;
		}
	}
	
	.home__featured-items__item {
		max-width: auto;
		width: 47.5%;
		float: left;
		&:nth-child(1) {
			margin-right: 5%;
		}
		.featured-items__item__content {
			padding: 1em;
		}
		.featured-items__item__image {
			padding-top: 1.5em;
		}
	}
	
	.home__teaching-resources__item {
		min-height: 16em;
	}

	
} //end min 64em

@media only all and (min-width: 68em) {
	
	.home__login-warning {
		width: 100%;
	}
	
	.home__login-warning__icon {
		width: 13%;
		height: 7em;
		.icon-padlock-cta {
			margin-top: 0.7em;
		}
	}

	.home__login-warning__message {
		width: 67%;
		height: 7em;
		padding: 1.2em 1.5em 1em 1.5em;
	}
	
	.home__login-warning__cta {
		width: 20%;
		float: left;
		height: 7em;
		padding: 1em;
		div {
			margin-top: 1.3em;
		}
	}
	
	.home__browse-films__messaging {
		width: 60%;
		height: 23em;
	}
	
	.home__browse-films__image {
		width: 40%;
		height: 23em;
		img {
			margin-top: 4em;
		}
	}
	
} //min 68em

@media only all and (min-width: 72.5em) {
  
  .home__browse-films__messaging {
		width: 55%;
		height: 25em;
	}
	
	.home__browse-films__image {
		width: 45%;
		height: 25em;
		img {
			margin-top: 3.5em;
		}
	}
	
	.home__featured-items__item {
		.featured-items__item__image {
			padding-top: 1em;
		}
	}
	
	.home__teaching-resources__item {
		width: 31.3333%;
		min-height: 19em;
		margin-bottom: 2em;
		&:nth-child(2n-1) {
			margin-right: 0;
		}
		&:nth-child(3n-1) {
			margin: 0 3% 2em 3%;
		}
		&:nth-child(4) {
			background-color: $darkest;
			.teaching-resources__item__heading {border-color: scale-color($darkest, $lightness: 25%);
			}
		}
	}
  
} // min 72.5em

@media only all and (min-width: 79em) {
	
	.home__featured-items__item {
		.featured-items__item__content {
			padding: 1em;
			width: 70%;
		}
		.featured-items__item__image {
			padding-top: 0;
			max-width: 13em;
			width: 30%;
			text-align: center;
			img {
				max-height: 7.1em;
				margin: 0 auto;
			}
		}
	}
	
} //end min 79em

@media only all and (min-width: 82.5em) {
	
	.home__browse-films {
		background-color: $contrast-alt-light;
		height: auto;
		display: inline-block;
	}
	
	.home__browse-films__messaging {
		width: 55%;
		height: 20em;
		padding: 1.5em 2em 1em 2em;
	}
	
	.home__browse-films__image {
		width: 45%;
		height: auto;
		img {
			margin-top: 0;
			display: block;
		}
	}
	
	.home__teaching-resources__item {
		min-height: 18em;
	}
	
} //end min 82.5em

@media only all and (min-width: 87em) {
	
	.home__browse-films__messaging {
		padding: 2em 2em 1em 2em;
	}
	
} //min 87em

@media only all and (min-width: 88.75em) {
	
	.home__teaching-resources__item {
		min-height: 17em;
	}

} //min 87em

@media only all and (min-width: 41em) and (max-width: 63.9375em) { 
	
	.home-featured__items .featured__item__info {
    width: 70%;
  }
  
  .home-featured__items .featured__item__image {
    width: 30%;
  }
	
} // min 41em max 63.9375em


@media only all and (min-width: 56em) {
  
  // Hide our usual nav trigger at this wide viewport, and bring in the one that's in the content area as a call to action.
  
  .front .login__container--trigger .login__trigger {
    visibility: hidden;
  }
  
  .front .login__container--trigger .login__trigger.active {
    visibility: visible;
  }
  
  .home-featured {
    margin-bottom: 2em;
  }
  
} //min 56em

@media only all and (min-width: 63.9375em) {
  
  .home-featured__items {
    width: 48%;
    float: left;
  }
  
  .home-featured__items.featured__item {
    width: 100%;
    a .heading {
	    display: inline;
	    &:after {
		    content: " - ";
	    }
    }
    a p {
	    display: inline;
    }
  }
  
  .home-featured__items .featured__item__info {
    width: 70.5%;
    padding: 1.6em 1.6em 1.8em 1.6em;
  }
  
  .home-featured__items .featured__item__image {
    width: 29.5%;
  }

  .front .calls-to-action__item .cta {
    margin: 0 0 2em 0;
  }
  
} //min 63.9375em

@media only all and (min-width: 63.9375em) and (max-width: 72.5em) {
  
  .home-featured__items .featured__item__info {
    width: 80%;
    padding: 1em 1.4em 1.2em 1.4em;
    .heading {
      margin: 0 0 0.4em 0;
    }
  }
  
  .home-featured__items .featured__item__image {
    width: 20%;
  }
  
} //min 63.9375em max 72.5em

@media only all and (min-width: 63.9375em) and (max-width: 87em) {
	
	.home-featured__items:nth-child(4) {
		display: none;
	}
	
} //min 67.9375em max 87em

@media only all and (min-width: 72.5em) and (max-width: 82.25em) {
  
  .home-featured__items .featured__item__info {
    width: 75%;
    padding: 1.4em 1.4em 1.5em 1.4em;
    .heading {
      margin: 0 0 0.6em 0;
    }
  }
  
  .home-featured__items .featured__item__image {
    width: 25%;
  }
  
} //min 72.5em max 88.25em