ul {
  list-style: disc inside; 
  padding-left: 0;
  margin: 0; 
  padding: 0;
}

ol {
  list-style: none;
  counter-reset: item; 
}

ul ul {
  list-style: circle inside; 
}

ul ul,
ul ol {
  margin: 1em 0 1em 2em;
  font-size: 1em;
}

li {
  margin-bottom: 1em; 
}

ol li:before { 
  content: counters(item, "."); 
  counter-increment: item; 
}