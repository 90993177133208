.useful-links .field-name-field-intro {
	padding: 0 0 1em 0;
	border-bottom: 0.15em solid scale-color($light-pastel, $lightness: 80%);
	margin: 0 0 2em 0;
	p {	
		font-weight: bold;
	}
}

.useful-links .view-useful-links .views-row {
	margin: 0 0 3em 0;
}

.useful-links .view-useful-links .views-row p {
	margin-bottom: 0.8em;
}

.useful-links .view-useful-links .views-row p:nth-child(1) a {
		font-weight: bold;
}


.useful-links .view-useful-links .views-row li {
	list-style-type: none;
}

@media only all and (min-width: 56em) {

  .useful-links .view-content { 
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 4em;
    -webkit-column-gap: 4em;
    column-gap: 4em;
  }
  
  .useful-links .view-content h3:first-child {
    margin-top: 0.5em;
  }
  
  .useful-links .view-content h3 {
    margin-bottom: 1em;
  }
      
} // min 56em

@media only all and (min-width: 79em) {
	
	.useful-links .view-content { 
    -moz-column-gap: 6em;
    -webkit-column-gap: 6em;
    column-gap: 6em;
  }
	
} //min 79em