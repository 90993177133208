.node-type-video-tutorial .tutorial__video {
  max-width: 40em;
  margin: 0 0 2em 0;
}

.node-type-video-tutorial .tutorial__content {
  margin: 0 0 3em 0;
}

.node-type-video-tutorial .items {
  margin: 0 0 3em 0;
  max-width: 25em;
}

.node-type-video-tutorial .items h4.heading {
  margin-top: 0;
}

@media only all and (min-width: 71.25em) {
  
  .node-type-video-tutorial .video-tutorial__content {
    float: left;
    width: 65%;
    margin-right: 4%;  
  }
  
  .node-type-video-tutorial .video-tutorial__secondary-content {
    float: right;
    width: 31%;
  }
}