.lt-ie9 .heading,
.lt-ie9 h1.guidance,
.lt-ie9 h2.guidance,
.lt-ie9 h3.guidance {
  font-weight: 700;
}

.lt-ie8 body {
  width: 60em;
  margin: 0 auto;
}

.lt-ie8 .header.guide {
  width: 52em;
  margin: 0 auto 1.5em auto;
}

.lt-ie8 .footer.guide {
  width: 52em;
  margin: 0 auto 0 auto;
}

.lt-ie8 .header__logo {
  display: block;
}

.lt-ie8 ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.lt-ie8 ol.pagination li {
  display: inline;
}

.lt-ie7 .image, .lt-ie7 .image img {
  width: 100%;
}

.ie8 .header__title {
  padding-top: 0;
}

.lt-ie9 .footer__twitter {
  max-width: 18em;
  width: 18em;
}