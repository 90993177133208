.node-type-video-tutorials-index .featured-video-tutorials__container {
  display: block;
  margin: 3em 0 3em 0;
  padding-bottom: 1em;
  border-bottom: 0.15em solid scale-color($light-pastel, $lightness: 60%);
  
  .featured-video-tutorials__item {
    margin: 0 0 3em 0;
  }
  
  .featured-video-tutorials__item__image {
    max-width: 9.1875em;
    margin: 0 0 1em 0;
    img {
	    width: 100%;
    }
  }
  
  .featured-video-tutorials__item__details .heading a {
    border-bottom: none;
    &:hover {
      text-decoration: underline;
    }
  }
  
   .featured-video-tutorials__item__details__link {
     font-size: 1.1em;
     font-weight: 300;
     &:hover {
       color: $dark-text;
       border-color: $dark-text;
     }
   }

} // .node-type-video-tutorials-index .featured-video-tutorials__container

.node-type-video-tutorials-index .video-tutorials__list {
  margin: 0 0 4em 0;
}

.node-type-video-tutorials-index .wysiwyg .featured-video-tutorials__container h2 {
	margin-bottom: 1em;
}

.node-type-video-tutorials-index .wysiwyg h3 {
	margin-top: 0;
}

@media only all and (min-width: 41em) {
  
  .node-type-video-tutorials-index .featured-video-tutorials__container {
    
    .featured-video-tutorials__item__image {
      float: left;
      margin: 0 2em 0 0;
    }
    
    .featured-video-tutorials__item__details {
      float: left;
      width: 70%;
      max-width: 37em;
    }
    
    .featured-video-tutorials__item__details .heading {
      margin-bottom: 0.5em;
    }
    
    .featured-video-tutorials__item__details p {
      &:after {
        content: " — ";
      }
    }
    
    .featured-video-tutorials__item__details p,
    .featured-video-tutorials__item__details__link {
      display: inline;
    }
    
  }// .node-type-video-tutorials-index .featured-video-tutorials__container
  
} //min 41em

@media only all and (min-width: 56em) {

  .node-type-video-tutorials-index .view-content { 
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 2em;
    -webkit-column-gap: 2em;
    column-gap: 2em;
  }
  
  .node-type-video-tutorials-index .view-content h3:first-child {
    margin-top: 0.5em;
  }
  
  .node-type-video-tutorials-index .view-content h3 {
    margin-bottom: 1em;
  }
  
  .node-type-video-tutorials-index .view-content .item-list {
    display: inline-block;
    width: 85%;
    margin: 0 15% 1.5em 0;
    -webkit-column-break-inside: avoid;
            page-break-inside: avoid; /* Makes effect only in Firefox v20+ */
                 break-inside: avoid; /* IE10+, Opera 11.1—12.1 */
  }
  
  .node-type-video-tutorials-index .view-content .item-list li {
    margin-bottom: 0.8em;
  }
    
} // min 56em

