// StyleGuide Imports (dependencies for this pattern): 
@import '../_2-mixins.scss'; @import '../_4-colours.scss';

// Mobile-first - stacking header with 100% wide
// sections, then enhanced via media query

.header {
  background-color: $header;
  width: 100%;
  margin: 0;
  text-align: center;
}

.header__part {
  height: 10.375em;
  background-repeat: no-repeat;
}

.header__part--brand {
  background-position: left top;
  a {border-bottom: none;}
  .icon-ss-logo {
    margin: 2em 8% 0 8%;
    width: 84%;
    height: 5.75em;
    max-width: 17.8125em;
  }
}

.header__search-trigger,
.nav__trigger {
  display: none;
  
  .trigger__container {
    width: 100%;
    margin: 0 auto 0 auto; 
  }
  .icon-arrow-down,
  .icon-arrow-up {
    width: 0.625em;
    margin: 1.375em 0 0 0;  
    float: right;
  }
  .icon-close {
    width: 1.25em;
    height: 1.3125em;
    margin: 1.03125em 0.85em 1.03125em 0;
    float: left;
  }
}

.js .header__search-trigger,
.js .nav__trigger {
  display: block;
  width: 100%;
  padding: 0 7% 0 8%;
}

.header__search-trigger {
  background-color: $contrast;
  cursor: pointer;
  border-bottom: 0.3em solid $contrast-light;
  .icon-search {
    width: 1.6875em;
    height: 1.75em;
    margin: 0.8125em 0.6em 0.8125em 0;
    float: left;
  }
  span {
    font-size: 1.3em;
    font-weight: 400;
    margin: 0.7em 0 0 0;
    color: $white;
    float: left;
  }
  &.active {
    border-bottom: none;
  }
}

.header__part--search {
  background-position: right top;
  border-bottom: 0.3em solid $dark;
  form {
    background-color: inherit;
    margin: 0;
    width: 100%;
    padding: 3.1em 0 0 0;
  }
  .search__container {
    width: 100%;
    max-width: 20em;
    margin: 0 auto 0 auto;
    text-align: left;
  }
  .form-item-search-block-form {
    float: left;
    text-align: left;
    width: 75%;
    max-width: 13.92em;
    display: inline-block;
  }
  label {
    display: none;
    font-size: 1.3em;
    font-weight: 600;
  }
  input[type="text"] {
   font-size: 1.1em;
   border: 0.135em solid $light; 
   padding: 0.36em;
   height: 2.1375em;
   &:focus {
     border: 0.135em solid $light-pastel;
   }
  }
  input[type="text"],
  input[type="submit"] {
    width: 100%;
    margin: 0;
  }
  .form-actions {
    width: 25%;
    display: inline-block;
    float: left;
  }
  input[type="submit"] {
    color: $white;
    background-color: $light;
    font-size: 1.1em;
    outline: 0;
    border: 0.135em solid $light;
    max-width: 4.55625em;
    height: 2.14em;
    &:focus,
    &:hover {
      background-color: $light-pastel;
      border: 0.135em solid $light-pastel;
      color: $mid;
    }
  }
  .search__advanced {
    display: inline-block;
    font-size: 1em;
    font-weight: 300;
    padding-top: 0.5em;
    a {
	    border-color: $white;
	    color: $white;
	  }
    a:focus,
    a:hover {
      color: $light-pastel;
      border-bottom: 0.1em dotted $light;
    }
  }
  // Fix / Hide Drupal junk *sigh*
  .block.block-search {
    //display: none; 
  }
  .form-item, 
  .form-actions {
    margin: 0;
  }
}

@media only all and (min-width: 41em) {
  
  // Hide the mobile nav triggers
  .js .header__search-trigger,
  .no-js .header__search-trigger {
    display: none;
  }
  
  // Bring our header halves back together
  .header__part--brand {
    width: 50%;
    float: left;
  }
  .header__part--search {
    width: 50%;
    float: left;
    border-bottom: none;
  }
  
  .header__part--search {
    form {
      padding: 1.3em 0 0 0;
      width: 90%;
      float: right;
    }
    .search__container {
      text-align: right;
    }
  }
  
  

  
  // This has the same effect as .container class; these fixes will
  // constrain the header items to an 80em width
  
  .header__part--brand {
    text-align: left;
  }
  .header__part--brand .icon-sos-logo {
    margin: 2em 0 0 10%;
  }
  .header__part--search {
    text-align: right;
    padding: 1.8em 5% 0 0;
  }
  .header__part--search .search__container {
    margin: 0 0 0 0;
    float: right;
    max-width: 18em;
  }
  .header__part--search .search__advanced {
    max-width: 12em;
  }
  
  // Navigation
  .js .nav__trigger {
    display: none;
  }
  
  .nav-collapse {
    display: block;
  }
    
} //end min 41em

@media only all and (min-width: 88.75em) {
  
  // This has the same effect as .container class; these fixes will
  // constrain the header items to an 80em width
  
  .header__part--brand {
    text-align: right;
  }
  .header__part--brand .icon-ss-logo {
    margin: 2em 22.5em 0 0;
  }
  .header__part--search {
    text-align: left;
    padding: 1.8em 0 0 0;
  }
  .header__part--search .search__container {
    margin: 0 0 0 22em;
    float: none;
  }
  
  
} //end min 88.75em