// StyleGuide Imports (dependencies for this pattern): 
@import '../_2-mixins.scss'; @import '../_4-colours.scss';

// Clearfix

.cta:before, .cta:after {
  display: table;
  line-height: 0;
  content: "";
}

.cta:after {clear: both;}

.cta {
  display: inline-block;
  width: 100%;
  margin: 0 0 1.5em 0;
  padding: 0.8em;
  background-color: $mid;
  border-left: 1em solid $dark-text;
  border-bottom: none;
  @include transition(background-color, 0.2s, ease-in-out); 
  &:hover {
    background-color: scale-color($mid, $lightness: 5%);
  }
  &:hover span {
    text-decoration: underline;
  }
}

.cta span {
  display: inline-block;
  padding-bottom: 0.5em;
  font-size: 1.2em;
  font-weight: 400;
  color: $white;
}

.cta .icon-circle-arrow,
.cta .icon-circle-arrow-white {
  width: 2.125em;
  height: 2.125em;
}

// Alternatives

.cta--light {
  background-color: $contrast;
  border-color: $contrast-light;
  span {
    color: $white;
  }
  &:hover {
    background-color: scale-color($contrast, $lightness: 7%);
  }
}

.cta--light-alt {
	background-color: $contrast-alt;
  border-color: $contrast-alt-light;
  &:hover {
    background-color: scale-color($contrast-alt, $lightness: 7%);
  }
}

.cta.icon-pattern {
  border-color: $light;
}

.cta--no-circle {
  div {display: none}
}

.cta--block {
  border: none;
  padding: 1.5em;
  span {
    font-weight: 600;
  }
}

.cta--block.icon-pattern {
  p {color: $white};
}

.cta--lockable {
	width: 100%;
	border-left: none;
	background-color: $darkest;
	color: $white;
	display: inline-block;
	padding: 0.4em;
	span {
		color: $white;
		width: 50%;
		height: 2em;
		float: left;
		display: inline-block;
		margin: 0 0 0.4em 0.3em;
	}
	.cta--lockable__lock {
		display: block;
		float: left;
		width: 1.375em;
		margin: 0.5em 0 0 0.2em;
	}
	.icon-lock-red {
		width: 1.1em;
		height: 1.5em;
	}
	.cta--lockable__arrow {
		display: block;
		float: right;
		width: 1.2em;
		height: 1.2em;
		margin: 0.5em 0.3em 0 0;
	}
	.icon-circle-arrow-white {
		width: 1.5em;
		height: 1.5em;
		padding: 0.2em;
		display: inline-block;
	}
	&:hover {
		background-color: scale-color($darkest, $lightness: 9%);
	} 
}

@media only all and (min-width: 20em) {
  
  .cta {
    border-left: 1.5em solid $dark;
    padding: 0.5em 0.8em 0.5em 0.8em;
  }
  
  .cta--light {
	  border-color: $contrast-light;
  }
  
  .cta--light-alt {
	  border-color: $contrast-alt-light;
  }
  
  .cta span {
    width: 80%;
    float: left;
    padding: 0;
  }
  
  .cta div {
    width: 20%;
    float: right;
    text-align: right;
    padding-top: 0.3em;
  }
  
  .cta--lockable {
		width: 100%;
		max-width: 14em;
		border-left: none;
		background-color: $darkest;
		padding: 0.6em;
		span {
			width: 60%;
			height: 1.2em;
			margin: 0.4em 0 0.4em 0.8em;
		}
		.cta--lockable__lock {
			margin: 0.1em 0 0 0.2em;
		}
		.icon-lock-red {
			width: 1.1em;
			height: 1.5em;
		}
		.cta--lockable__arrow {
			display: block;
			float: right;
			width: 1.2em;
			height: 1.2em;
			margin: 0.2em 0.3em 0 0;
		}
		.icon-circle-arrow-white {
			width: 1.5em;
			height: 1.5em;
			padding: 0 0.2em 0.2em 0.2em;
			display: inline-block;
		}
	}

  
    
  // Alternatives
  
  .cta--no-circle {
    span {
      width: 100%;
      padding-bottom: 0.8em;
    }
  }
   
  .cta--block {
    border: none;
    span {
      width: 100%;
      margin-bottom: 0.5em;
    }
    p {
      width: 80%;
      float: left;
    }
  }
  
  .cta--block.cta--no-circle {
    span,
    p {
      width: 100%;
    }
  }
  
} //end min 20em

@media only all and (min-width: 30em) {
  
  .cta {
    padding: 0.6em 0.8em 0.6em 0.8em;
  }
  
  .cta span {
    font-size: 1.3em;
    padding: 0.4em 0 0 0;
  }
  
  // alternatives
  
  .cta--block {
    padding: 1.7em;
  }
  
  .cta--lockable {
		padding: 0.6em;
		span {
			padding: 0;
		}
	}

} // end min 30em

@media only all and (min-width: 32.5em) {
  
  // Alternatives
  
  .cta--no-circle {
    padding: 1.5em 1em 1.5em 1em;
    span {
      padding-top: 0;
    }
  }
  
  .cta--lockable {
		padding: 0.6em;
	}
  
} //end min 32.5em

@media only all and (min-width: 58.75em) {
  
  .cta {
    padding: 0.6em 1.2em 0.6em 1.2em;
  }
  
  .cta--lockable {
		padding: 0.6em;
	}
  
} //end min 32.5em

