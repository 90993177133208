// StyleGuide Imports (dependencies for this pattern): 
@import '../_2-mixins.scss'; @import '../_4-colours.scss';

.login__trigger.cta,
.logout__trigger.cta {
  font-size: 0.9em;
  max-width: 10em;
  padding: 0.5em 0.8em 0.5em 0.8em;
  margin: 0 auto 0 auto;
  display: block;
  border-left: 1em solid $dark-text;
  cursor: pointer;
  .icon-arrow-down,
  .icon-arrow-up,
  .icon-arrow-right {
    width: 0.625em;
    margin: 0.7em 0 0 0;  
    float: right;
  }
  .icon-arrow-right {
    margin: 0.5em 0 0 0;
  }
  span {
    width: auto;
    font-size: 1.1em;
    text-transform: uppercase;
    padding: 0.2em 0 0 0;
    float: left;
  }
  div {
    width: auto;
    padding: 0;
  }
}

.login__trigger--top.cta {
  max-width: 100%;
  text-align: center;
  padding: 0.5em 8% 0.5em 5%;
}

.login__trigger--top.cta .login__trigger--top__container {
  display: inline-block;
  width: 100%;
  max-width: 24.4em;
  margin: 0 auto;
  float: none;
}

.login__sso,
.login__cms {
  padding: 1.5em 8% 1.5em 8%;
}

.login__sso {
  background-color: $mid-text;
  .sso__block__label {
    width: 100%;
    font-size: 1.2em;
    color: $white;
    display: inline-block;
    margin: 0 0 1em 0;
  } 
  .login__sso__block--shibboleth .btn {
    margin-bottom: 0;
    &:hover { //default $contrast-alt :hover is awful against $mid
      box-shadow: inset 0 0 0 0.2em scale-color($contrast-alt, $lightness: 7%);
    }
  }
}

.login__cms {
  padding-top: 2em;
  background-color: $dark;
  .login__cms__label {
    font-size: 1.2em;
    color: $white;
    display: inline-block;
    margin: 0 0 0.5em 0;
  }
  form {
    padding: 0;
    max-width: 20em;
    margin: 0;
  }
  .item-list {
    margin-bottom: 0.5em;
  }
  .item-list ul,
  .item-list ul li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .item-list a {
    color: $light-pastel;
    &:hover {
      color: $white;
      border-bottom: 0.1em dotted $white;
    }
  }
  form .btn {
    margin: 0;
  }
  input[type=text],
  input[type=password] {
    margin-bottom: 0;
  }
}

@media only all and (min-width: 37em) {
  
  .login__sso {
    .sso__block__label {
      width: auto;
      margin-right: 1em;
    }
  }
    
} //min 41em


@media only all and (min-width: 41em) {
  
  .login__trigger.cta,
  .logout__trigger.cta {
    background-color: $mid-text;
    background-image: none; 
  }
  
  .login__trigger--top.cta {
    background-color: scale-color($dark-text, $lightness: -30%);
    padding: 0.7em 8% 0.5em 8%;
    &:hover {
      background-color: $dark-text;
    }
  }
  
  .login__trigger--top.cta .login__trigger--top__container {
    max-width: 10em;
  }
  
  .login__sso {
    background-color: $mid;
    padding-bottom: 2em;
    .login__sso__block {
      width: 50%;
      float: left;
    }
    .login__sso__block span {
      width: 100%;
    }
    .login__sso__block--rm-unify .btn {
      margin-bottom: 0;
    }
  }
  
} //min 41em

@media only all and (min-width: 46.625em) {
  
  .login__trigger.cta,
  .logout__trigger.cta {
    float: right;
  }
  
  .login__trigger--top.cta {
    display: none;
  }
  
  .login__cms form {
    max-width: 80em;
  }
  
  .login__cms .form-item {
    width: 40%;
    float: left;
    margin-right: 5%;
    margin-top: 0.9em;
  }
  
  .login__cms .form-actions {
    float: left;
    width: 10%;
    .btn {
      height: 2.275em;
      margin-top: 1.6em;
    }
  }
  
} //min 50em

@media only all and (min-width: 50em) and (max-width: 79em) {
  
  .login__trigger.cta,
  .logout__trigger.cta {
    margin: 0 5% 0 0;
  }
  
} //

@media only all and (min-width: 63.9375em) {
  
  .login__cms {
    padding-top: 1em;
  }
  
  .login__cms .login__cms__label {
    width: 20%;
    margin: 2em 10% 0.5em 0;
    float: left;
  }
  
  .login__cms form {
    width: 70%;
    float: left;
  }
  
  .login__cms .item-list {
    float: left;
    width: 100%;
  }
  
} //min 63.9375em

@media only all and (min-width: 71.25em) {
  
  .login__cms .login__cms__label {
    width: 20%;
    margin-right: 5%;
  }
  
  .login__cms form {
    width: 75%;
    float: left;
  }
  
  .login__trigger.cta.active {
    margin-top: 0em;
  }
  
  
} //min 71.25em

@media only all and (min-width: 77em) {
  
    .login__sso {
      padding-bottom: 1.5em;
    }
  
    .login__sso .login__sso__block span {
      width: auto;
    }
  
} //min 77em 

@media only all and (min-width: 79em) {
  
    .login__cms .login__cms__label {
      width: 13.125em;
      margin-right: 0.5em;
    }
      
    .login__trigger.cta,
    .logout__trigger.cta {
      margin-right: 5%;
    }

} //min 79em

@media only all and (min-width: 88.75em) {
  
  .login__trigger.cta,
  .logout__trigger.cta {
    margin-right: 0;
  }
  
  .login__cms .form-item {
    width: 30%;
    float: left;
    margin-right: 5%;
  }
  
} // min 78.5em