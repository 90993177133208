@media print {
  header, 
  nav,
  .nav__trigger,
  .login,
  .login__container--trigger, 
  .footer__top, 
  .footer__nav,
  footer,
  iframe,
  .video-container {
    display: none !important;
  }
  
  .icon-body-background {
	  background-image: none;
  }
  
  .single-film__video {
	  margin: 0 !important;
  }
  
  .js-tabs .tabs [role=tablist] {
    display: none;
  }
  
  .js-tabs .tabs .js-panel__title,
  .js-tabs .tabs .js-panel {
  	display: block;
  }
  
  .related-clips--sidebar {
    display: none;
  }
  
  .related-clips--bottom {
    display: block;
  }
  
  tr,
  tbody tr:nth-child(odd) {
	  border-top: none;
	  padding: none;
	  border-bottom: none;
	  background-color: inherit;
  }
  
  table {
	  border-bottom: none;
  }
  
  .node-type-lesson-guide .associated__clips__thumb {
	  padding: 0 !important;
	  border: 0 !important;
	  overflow: auto !important;
	  height: auto !important;
	  width: inherit !important;
	  max-width: 100% !important;
	  .associated__clips__thumb__image {
		  display: none !important;
		  margin: 0 !important;
	  }
	  float: none !important;
	  text-align: left !important;
	  margin: 0 0 0 0 !important;
  }
  
}