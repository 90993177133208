.help-faq .field-name-field-intro p {
	font-weight: bold;
	margin-bottom: 3em;
}

.help-faq .faq__container {
	display: inline-block;
	padding-top: 2em;
	border-top: 0.15em solid scale-color($light-pastel, $lightness: 80%);
	margin-bottom: 2em;
}