.heading {
  font-weight: 600; //Semibold - see below
  font-style: normal;
  color: $dark-text;
  margin: 0 0 1em 0;
  line-height: 1.2em;
}

.heading--mid {
  color: $light;
}

.heading--pairing {
  margin: 0 0 0.15em 0;
}

.heading--large {
  font-size: 2.3em;
}

.heading--medium {
  font-size: 1.9em;
}

.heading--small {
  font-size: 1.7em;
}

.heading--smallest {
  font-size: 1.4em;
}

p,
ul,
ol,
.text {
  font-size: 1.15em;
  line-height: 1.4em;
  font-style: normal;
  font-weight: 300;
  color: $dark-text;
}

p,
.text {
  margin-bottom: 1.3em;
  max-width: 34em;
}

.text--small {
  font-size: 1em;
}

b, 
strong {
  font-weight: 600;
}

i, 
em {
  font-style: italic;
}

a {
  color: $light;
  border-bottom: 0.1em dotted $mid;
  text-decoration: none;
}

.link--dark {
  color: $dark-text;
  border-bottom: 0.1em dotted $dark;
}

.link--mid {
  color: $mid;
  border-bottom: 0.1em dotted $mid;
}

.link--light {
  color: $light;
  border-bottom: 0.1em dotted $light;
}

.link--light-pastel {
  color: $light-pastel;
  border-bottom: 0.1em dotted $light-pastel;
}

.link--white {
  color: $white;
  border-bottom: 0.1em dotted $white;
}

// Utility class for adjustments on user-edited content

.node-type-page .wysiwyg,
.node-type-about-contact .wysiwyg,
.node-type-about .wysiwyg,
.node-type-mie .wysiwyg,
.node-type-mie-and-curriculum .wysiwyg,
.node-type-resources .wysiwyg,
.node-type-teaching-activity .wysiwyg,
.node-type-getting-creative .wysiwyg,
.node-type-useful-links-index .wysiwyg,
.node-type-classroom-worksheet .wysiwyg,
.node-type-film-clip .js-panel .wysiwyg,
.useful-links .view-content,
.video-tutorials__list.wysiwyg,
.node-type-video-tutorial .wysiwyg,
.restricted {
  
  margin: 0 0 4em 0;
  
  img {
	  width: auto;
    max-width: 100%;
    height: auto;
    margin: 0.6em 0 0.2em 0;
  }
  
  ul, ol {
    margin: 0 0 1.5em 0;
    max-width: 34em;
    li p {
      font-size: 1em;
      display: inline;
    }
  }
  
  em {
    display: inline-block;
    max-width: 34em;
  }
  
  h1 {
    color: $mid-text;
    margin: 0 0 0.4em 0;
  }
  
  h2 {
    font-size: 1.8em;
    margin: 1.1em 0 0.5em 0;
  }
  
  h2:first-child {
    margin: 0 0 0.5em 0;
  }
  
  h3 {
    font-size: 1.5em;
    margin: 1.3em 0 0.6em 0;
  }
  
  h4, h6 {
    color: $mid-text;
    font-size: 1.5em;
    margin: 1.3em 0 0.6em 0;
  }
  
  h5, h6 {
    font-size: 1.5em;
    margin: 1.3em 0 0.6em 0;
  }
  
  a:hover {
    color: $dark-text;
    border-bottom: 0.1em dotted $dark-text;
  }
  
  .item-list ul li .views-field-title {
    display: inline;
  }
  
} //wysiwyg

//// Proxima Nova - Typekit Weights and Styles available 
// in the SFE Kit for reference;

/* Light 
  font-weight: 300;
  font-style: normal;
  
// Light Italic 
  font-weight: 300;
  font-style: italic;

// Regular 
  font-weight: 400;
  font-style: normal;

// Semibold
  font-weight: 600;
  font-style: normal;
  
font-family: "proxima-nova";

//////// */