.legacy-ie {
  display: none;
}

.lt-ie8 .legacy-ie {
  display: block;
  width: 100%;
  background-color: $error;
  padding: 1.2em 1.5em;
  color: $dark-text;
  font-size: 1.2em;
  font-weight: 600;
}

.lt-ie9 {
  .main-nav li a { 
    color: $white !important;  
  }
  
  .home-featured__items,
  .home-featured__items.featured__item {
    background-image: none;
  }
  
  .resources__item__image-container__image {
    left: 0;
    top: 0;
    position: relative;
  }
  
  .help-faq .faq__container {
    display: block;
  }
}

.lte-ie9 {
  .btn {
    border: none;
  }
}