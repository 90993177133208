.glossary .list__content .list__content__item span {
	font-style: normal;
	display: inline-block;
	margin: 0 0 0.7em 0;
	font-size: 1.1em;
	color: $contrast;
	font-weight: bold;
	a {margin: 0;}
	&:before {
		content: none;
	}
}

.glossary .list__content__container li p {
	font-size: 1em;
}

.glossary .list__content__item {
	margin: 0 0 2em 0;
}

.glossary .list__content__container .heading {
	padding-bottom: 1em;
	border-bottom: 0.1em solid scale-color($contrast-alt, $lightness: 80%);
}