.resources__item {
  margin: 0 0 3em 0;
  color: $white;
  width: 100%;
  text-align: center;
  a {
	  border: none;
  }
  background-color: $dark-text;
  .resources__item__image-container {
    background-color: scale-color($dark-text,$lightness: -30%);
  }
}

.resources__item .icon-circle-arrow {
  width: 2.125em;
  height: 2.125em;
  border-radius: 1.0625em;
  border: 0.15em solid $white;
}

.resources__item__image-container {
  margin: 0 0 1em 0;
  text-align: center;
  line-height: 0;
  display: inline-block;
  width: 100%;
}

.resources__item__image-container__image {
  display: inline-block;
  max-width: 100%;
  //max-width: 18.75em;
  margin: 0 auto 0 auto;
}

.resources__item__headline {
  padding: 0 8% 0 8%;
  font-size: 1.4em;
  margin: 0 0 1em 0;
  color: $white;
}

.resources__item__content {
  padding: 0 8% 0 8%;
  font-size: 1.1em;
  font-weight: 300;
  margin: 0 0 1.5em 0;
  color: $white;
}

.resources__item__link {
  padding: 0 0 1.5em 0;
  text-align: center;
  border-bottom: 0;
  width: 100%;
  display: inline-block;
  color: $white;
}

@media only all and (min-width: 45em) {
  
  //Two-up for image and content inside blocks
  
  .resources__a //sorry harry 
  {
	  max-width: 48.5em;
	  display: inline-block;
  }
  
  .resources__item {
    text-align: left;
    position: relative;
    &:last-child {
      margin-bottom: 5em;
    }
  }
  
  .resources__item__image-container {
    float: left;
    width: 40%;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 18.75em;
  }
  
  .node-type-resources .wysiwyg img.resources__item__image-container__image {
	  margin: 0;
  }
  
  .resources__item__image-container__image {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    position: absolute;
    max-width: 100%;
    max-height: 100%;
  }
  
  .resources__item__content-container {
    width: 60%;
    position: static;
    display: inline-block;
    margin-left: 40%;
    padding: 0 0 1em 4%;
  }
  
  .resources__item__headline,
  .resources__item__content,
  .resources__item__link {
    position: static;
    display: inline-block;
    float: left;
    padding: 0;
  }
  
  .resources__item__headline,
  .resources__item__content {
    width: 80%;
    padding: 0 4% 0 0;
  }
  
  .resources__item__headline {
    padding: 1.2em 4% 0 0;
  }
  
  .resources__item__link {
    width: 20%;
    margin-top: 0.5em;
  }
  
} // min 45em
