// General page layout

.page-title__original-title,
.page-title__details {
	font-weight: 300;
}
.page-title__details {
	color: #C8412A;
}

@media only all and (min-width: 63.9375em) {
  
  .node-type-page .wysiwyg,
  .node-type-about .wysiwyg,
  .node-type-about-contact .wysiwyg,
  .node-type-mie .wysiwyg,
  .node-type-mie-and-curriculum .wysiwyg,
  .node-type-teaching-activity .wysiwyg,
  .node-type-getting-creative .wysiwyg,
  .node-type-help-faq .wysiwyg,
  .node-type-screen-p-g .wysiwyg {
    display: inline-block;
    width: 70%;
    float: left;
    margin-right: 4%;
  }
  
  .node-type-page .subnav,
  .node-type-about .subnav,
  .node-type-about-contact .subnav,
  .node-type-mie .subnav,
  .node-type-mie-and-curriculum .subnav,
  .node-type-teaching-activity .subnav,
  .node-type-getting-creative .subnav,
  .node-type-help-faq .subnav,
  .node-type-screen-p-g .subnav {
    display: inline-block;
    width: 26%;
    float: right;
  }
  
} // min 63.9375em 


@media only all and (min-width: 77em) {
  
  .node-type-page .wysiwyg,
  .node-type-about .wysiwyg,
  .node-type-about-contact .wysiwyg,
  .node-type-mie .wysiwyg,
  .node-type-mie-and-curriculum .wysiwyg,
  .node-type-teaching-activity .wysiwyg,
  .node-type-getting-creative .wysiwyg,
  .node-type-help-faq .wysiwyg,
  .node-type-screen-p-g .wysiwyg {width: 60%}
  
  .node-type-page .subnav,
  .node-type-about .subnav,
  .node-type-about-contact .subnav,
  .node-type-mie .subnav,
  .node-type-mie-and-curriculum .subnav,
  .node-type-teaching-activity .subnav,
  .node-type-getting-creative .subnav,
  .node-type-help-faq .subnav,
  .node-type-screen-p-g .subnav {width: 36%}

} // min 77em 