.los-redirect-modal {

  display: none;

  &.los-redirect-modal--active {
    display: block;
  } 

  .los-redirect-modal__backdrop {
    background-color: rgba(20,20,20,0.8);
    z-index: 950;
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  .los-redirect-modal__dialog {
    position: fixed;
    z-index: 999;
    background-color: $white;
    top: 50vh;
    left: 50vw;
    transform: translateX(-50%) translateY(-50%);
    width: 90vw;
    max-width: 52rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .los-redirect-modal__header {
      background-color: #122E09;
      padding: 1rem;
      @media only all and (min-width: 36em) {
        padding: 2rem 4rem;
      }
      .los-redirect-modal__header__heading {
        color: $white;
        margin: 0;
        font-size: 1.6rem; 
        @media only all and (min-width: 36em) {
          font-size: 2.25rem;
        }  
      }
    }
    .los-redirect-modal__body {
      background-color: #43693C;
      color: $white;
      background-image: url('../../assets-img/los-modal-bg.png');
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 40%;
      padding: 1rem;
      @media only all and (min-width: 36em) {
        padding: 2rem 4rem;
      }
      p {
        color: $white;
        max-width: 35rem;
        font-size: 1.1rem;
        @media only all and (min-width: 36em) {
          font-size: 1.375rem;
        }
      }
      p.los-redirect-modal__body__small {
        @media only all and (min-width: 36em) {
          font-size: 1.1rem;
          margin-bottom: 2rem;
        }
      }
      .los-redirect-modal__body__cta {
        background-color: #122E09;
        color: $white;
        padding: 0.5rem 1rem;
        margin: 0 0 0.5rem;
        display: inline-block;
        border: 0;
        @media only all and (min-width: 36em) {
          font-size: 1.375rem;
        }
      }
    }

  }

}