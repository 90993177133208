#user-pass-reset,
#user-pass {
  margin-bottom: 3em;
}

.page-user-reset .breadcrumb {
	display: none;
}

.page-user-reset #user-profile-form {
	margin-bottom: 5em;
}

.page-user-reset .form-item {
	max-width: 100%;
}

.page-user-reset .password-strength {
	width: 90%;
	max-width: 20em;
	float: none;
	color: $white;
	.password-indicator {
		margin: 1em 0 1.5em 0;
	}
}

.page-user-reset .form-type-password {
	margin-bottom: 0;
}

.page-user-reset div.password-confirm {
	float: none;
	margin-top: 0;
	margin-bottom: 1em;
	background-color: $error;
	padding: 0.5em;
	border: 0.2em solid $dark;
}

.page-user-reset div.form-item div.password-suggestions {
	border: 0;
	width: 100%;
	max-width: 17em;
	background: scale-color($dark, $lightness: -20%);
	padding: 1em;
	margin: 0 0 1em 0;
	ul {
		margin: 1em 0 0 0;
	}
	li {
		color: $white;
		font-size: 0.85em;
	}
}

.page-user-reset #edit-timezone {
	display: none;
}