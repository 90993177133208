// StyleGuide Imports (dependencies for this pattern): 
@import '../_2-mixins.scss'; @import '../_4-colours.scss';

body {
  background-color: $white;
  // background-image is set for us by grunticon; otherwise
  // you'd include the pattern commented out below.
  background-attachment: fixed;
  background-size: contain;
  @media only all and (min-width: 56em) {
    background-size: auto; 
  }
}

/*
body {
  background: url(../../assets-icons/body-background.png);
  background: url(../../assets-icons/body-background.svg),
    linear-gradient(transparent, transparent);
    
    // ^ This trick ensures that our background SVG is only 
    // used in browsers that support it (a 'coincidence'
    // feature test): https://css-tricks.com/a-complete-guide-to-svg-fallbacks/#fallback-svg-css-background-image 
}
*/
