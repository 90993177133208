.page-search .search-form {
  margin: 0 0 3.5em 0;
  padding-bottom: 1em;
}

.page-search .search-form .form-item-keys label {
	display: block;
}

.page-search .heading--wideonly {
	display: none;
}

.page-search .search-advanced input[type="text"] {
  max-width: 25em;
} 

.page-search .search-form input[type="submit"] {
  height: 2.3em;
  margin: 0 auto 1em 0.2em;
}

.page-search .search-advanced:before, 
.page-search .search-advanced:after {
  display: table;
  line-height: 0;
  content: "";
}

.search-advanced:after {clear: both;}


.js .page-search fieldset.collapsible .fieldset-legend {
  display: block;
  background-image: none;
  padding: 0 0 1em 0.2em;
  &:after {
    content: " ^";
  }
}

.js .page-search fieldset.collapsed .fieldset-legend {
  display: block;
  background-image: none;
  padding: 0 0 1em 0.2em;
  &:after {
    content: " »";
  }
}

.page-search .fieldset-wrapper {
  padding-top: 1.5em;
  border-top: 0.1em solid scale-color($dark-text, $lightness: -20%);
}

.page-search .search-advanced .criterion {
  margin-right: 0;
}

.page-search .search-advanced .form-checkboxes {
  width: 100%;
  margin: 1em 0 2em 0;
  background-color: scale-color($dark-text, $lightness: -30%);
  padding: 1em;
  .form-type-checkbox {
    margin-bottom: 0.5em;
  }
  input[type="checkbox"] {
    margin: 0 0.5em 0 0;
  }
  .option {
    display: inline-block;
    vertical-align: -0.1em;
  }
}

.page-search .search-advanced input[type="submit"] {
  margin-bottom: 0;
}

.page-search .search-results .title {
	font-size: 1.1em;
	display: inline-block;
	margin: 0 0 0.8em 0;
	a {
		border-bottom: 0.05em dotted $mid-text;
	}
}

.page-search .search-results p {
	font-size: 1em;
	margin: 0 0 2.5em 0;
	strong {
		background-color: scale-color($contrast, $lightness: 70%);
		padding: 0 0.2em 0 0.2em;
	}
}

@media only all and (min-width: 40em) {
  
  .search-advanced {
    margin-top: 1em;
    display: block;
  }
  
  .page-search .search-advanced .criterion {
	  float: none;
  }
  
} //min 40em


@media only all and (min-width: 42em) {
	
	.page-search .search-advanced .criterion {
		float: left;
	}
	
	.page-search .search-advanced .criterion:nth-child(1) {
		width: 60%;
	}
  
  .page-search .search-advanced .form-type-checkboxes {
    margin-left: 2em;
  }
	
} //min 42em
