// StyleGuide Imports (dependencies for this pattern): 
@import '../_2-mixins.scss'; @import '../_4-colours.scss';

/* Buttons / CTAs */

.btn {
  font-size: 1.13em;
  background-color: $dark;
  color: #fff;
  display: inline-block;
  padding: 0.6em 1.2em;
  font-weight: 600;
  cursor: pointer;
  margin: 0 1em 1.5em 0;
  @include transition(background-color,0.15s); // Kin Mixin
  border-top: none;
  border-bottom: 0.2em solid scale-color($dark, $lightness: -50%);
  &:hover {
    text-decoration: underline;
    box-shadow: inset 0 0 0 0.2em scale-color($dark, $lightness: 30%);
    background-color: scale-color($dark, $lightness: 15%);
    border-bottom: 0.2em solid scale-color($dark, $lightness: -35%);
  }
}

.btn--darkest {
  background-color: $darkest;
  border-bottom: 0.2em solid scale-color($darkest, $lightness: -30%);
  &:hover {
    box-shadow: inset 0 0 0 0.2em scale-color($darkest, $lightness: 30%);
    background-color: scale-color($darkest, $lightness: 10%);
    border-bottom: 0.2em solid scale-color($darkest, $lightness: -10%);
  }
}

.btn--mid {
  background-color: $mid;
  border-bottom: 0.2em solid scale-color($mid, $lightness: -30%);
  &:hover {
    box-shadow: inset 0 0 0 0.2em scale-color($mid, $lightness: 30%);
    background-color: $mid;
    border-bottom: 0.2em solid scale-color($mid, $lightness: -10%);
  }
}
.btn--light {
  background-color: $light;
  color: $white;
  border-bottom: 0.2em solid scale-color($light, $lightness: -30%);
  &:hover {
    box-shadow: inset 0 0 0 0.2em scale-color($light, $lightness: 40%);
    background-color: $light;
    border-bottom: 0.2em solid scale-color($light, $lightness: -20%);
  }
}
.btn--light-pastel {
  background-color: $light-pastel;
  color: $dark-text;
  border-bottom: 0.2em solid scale-color($light-pastel, $lightness: -20%);
  &:hover {
    box-shadow: inset 0 0 0 0.2em scale-color($light-pastel, $lightness: 40%);
    background-color: $light-pastel;
    border-bottom: 0.2em solid scale-color($light-pastel, $lightness: -10%);
  }
}
.btn--contrast {
  background-color: $contrast;
  border-bottom: 0.2em solid scale-color($contrast, $lightness: -40%);
  &:hover {
    box-shadow: inset 0 0 0 0.2em scale-color($contrast, $lightness: 30%);
    background-color: $contrast;
    border-bottom: 0.2em solid scale-color($contrast, $lightness: -30%);
  }
}
.btn--contrast-alt {
  background-color: $contrast-alt;
  border-bottom: 0.2em solid scale-color($contrast-alt, $lightness: -40%);
  &:hover {
    box-shadow: inset 0 0 0 0.2em scale-color($contrast-alt, $lightness: 20%);
    background-color: $contrast-alt;
    border-bottom: 0.2em solid scale-color($contrast-alt, $lightness: -20%);
  }
}

.btn--white {
  background-color: $white;
  border-bottom: 0.2em solid scale-color($white, $lightness: -30%);
  color: $dark-text;
  &:hover {
    box-shadow: inset 0 0 0 0.2em $light-pastel;
    background-color: $white;
    border-bottom: 0.2em solid scale-color($white, $lightness: -30%);
  }
}

.btn--disabled {
  background-color: #ccc;
  border-bottom: 0.2em solid scale-color($white, $lightness: -30%);
  color: #666;
  &:hover {
    text-decoration: none;
    box-shadow: none;
    background-color: #ccc;
    border-bottom: 0.2em solid scale-color($white, $lightness: -30%);
  }
}

/* Removes awkward default styles on some inputs for mobile */
input[type="button"], 
input[type="reset"], 
input[type="submit"] {
  -webkit-appearance: none;
   -moz-appearance: none;
        appearance: none; 
}

/* Removes awkward UA side borders set, and pads to right height */
button,
input[type="button"], 
input[type="reset"], 
input[type="submit"] {
  border-left: 0;
  border-right: 0;
  height: 3em;
}